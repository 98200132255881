import React from "react";

const Currency = (props: {
  cents: number | null | undefined;
  change?: boolean;
  round?: boolean;
}) => {
  let options: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "USD",
  };
  if (props.round) options["maximumFractionDigits"] = 0;
  const formatter = new Intl.NumberFormat("en-US", options);
  return props.cents ? (
    <span
      className={
        props.change ? (props.cents > 0 ? "positive" : "negative") : ""
      }
    >
      {formatter.format(props.cents / 100)}
    </span>
  ) : (
    <span>$0.00</span>
  );
};
export default Currency;
