import { Database } from "../lib/database.types";
import { supabase } from "./supabaseClient";

export type BetRow = Database["public"]["Tables"]["bets"]["Row"];
export type InsertBet = Database["public"]["Tables"]["bets"]["Insert"];

export type InsertBetResponse = Awaited<ReturnType<typeof insertBet>>;

export type BetsResponse = Awaited<ReturnType<typeof queryBets>>;
export type BetsResponseSuccess = BetsResponse["data"];
export type BetsResponseError = BetsResponse["error"];

export type ChangesByDateResponse = Awaited<
  ReturnType<typeof queryChangeByDate>
>;
export type ChangesByDateSuccess = ChangesByDateResponse["data"];
export type ChangesByDateError = ChangesByDateResponse["error"];

export const insertBet = async (
  newBet: Database["public"]["Tables"]["bets"]["Insert"]
) => {
  return await supabase.from("bets").insert(newBet);
};

export const updateBet = async (
  bet: Database["public"]["Tables"]["bets"]["Update"]
) => {
  return await supabase.from("bets").update(bet).eq("id", bet.id);
};

export const queryBets = async () => {
  return await supabase
    .from("bets")
    .select()
    .is("change_cents", null)
    .order("event_date")
    .order("id");
};

export const queryChangeByDate = async () =>
  await supabase.from("change_by_date").select();

export const updateWin = async (bet: BetRow) => {
  const change_cents = bet.potential_payout_cents - bet.wager_cents;
  return await supabase
    .from("bets")
    .update({ change_cents: change_cents })
    .eq("id", bet.id);
};

export const updateLose = async (bet: BetRow) => {
  const change_cents = -bet.wager_cents;
  return await supabase
    .from("bets")
    .update({ change_cents: change_cents })
    .eq("id", bet.id);
};

export const updatePush = async (bet: BetRow) => {
  return await supabase
    .from("bets")
    .update({ change_cents: 0 })
    .eq("id", bet.id);
};
