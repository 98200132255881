import React from "react";
import Calendar, { Detail } from "react-calendar";
import { ChangesByDateSuccess } from "../queries";
import Currency from "./Currency";

const getDateStr = (date: Date) => {
  return date.toISOString().split("T")[0];
};

const CHANGE_FN_BY_VIEW: Record<
  Detail,
  (date: Date, changeByDateMap: Record<string, number>) => number
> = {
  month: (date: Date, changeByDateMap: Record<string, number>) =>
    changeByDateMap[getDateStr(date)],
  year: (date: Date, changeByDateMap: Record<string, number>) =>
    Object.entries(changeByDateMap)
      .filter((entry) => entry[0].slice(0, 7) === getDateStr(date).slice(0, 7))
      .reduce((acc, entry) => acc + entry[1], 0),
  decade: (date: Date, changeByDateMap: Record<string, number>) =>
    Object.entries(changeByDateMap)
      .filter((entry) => entry[0].slice(0, 4) === getDateStr(date).slice(0, 4))
      .reduce((acc, entry) => acc + entry[1], 0),
  century: (date: Date, changeByDateMap: Record<string, number>) => 0,
};

const ChangeCalendar = (props: { changesByDate: ChangesByDateSuccess }) => {
  const changeByDateMap = props.changesByDate?.reduce((acc, change) => {
    return {
      ...acc,
      [change.event_date || ""]: change.change_cents,
    };
  }, {}) as Record<string, number>;
  const tileContent = (opts: { date: Date; view: Detail }) => {
    const changeFn = CHANGE_FN_BY_VIEW[opts.view];
    const change = changeFn(opts.date, changeByDateMap);
    return (
      <div className="cal-change">
        {change && <Currency cents={change} change round />}
      </div>
    );
  };

  return <Calendar value={new Date()} tileContent={tileContent} />;
};

export default ChangeCalendar;
