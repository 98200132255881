import React, { FormEvent } from "react";
import { useState } from "react";
import {
  Button,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { supabase } from "../supabaseClient";
import Header from "./Header";

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: { emailRedirectTo: window.location.origin },
      });
      if (error) {
        alert(error.message);
      }
      alert("Check your email for the login link!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row>
        <Header />
      </Row>
      <Row>
        <p>Sign in via magic link with your email below</p>
      </Row>
      <Row>
        {loading ? (
          "Sending magic link..."
        ) : (
          <Form onSubmit={handleLogin}>
            <InputGroup>
              <FormControl
                id="email"
                className="inputField"
                type="email"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button type="submit">Send magic link</Button>
            </InputGroup>
          </Form>
        )}
      </Row>
    </Container>
  );
}
