import React from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";

import InputGroup from "react-bootstrap/InputGroup";

import { newBetSchema } from "../validationSchema";
import { DateTime } from "luxon";
import { Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { InsertBetResponse, insertBet, InsertBet, updateBet } from "../queries";

type InitialValues = {
  id: number;
  event_date: string;
  book: string;
  description: string;
  wager_cents: string;
  free_bet_cents: string;
  potential_payout_cents: string;
};

type NewBetFormProps = {
  userId: string;
  values?: InitialValues;
  onSuccess?: () => void;
};

const NewBetForm = (props: NewBetFormProps) => {
  const queryClient = useQueryClient();
  const dbFn = props.values ? updateBet : insertBet;
  const newBetMutation = useMutation<InsertBetResponse, unknown, InsertBet>(
    async (newBet) => await dbFn(newBet),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bets");
        props.onSuccess?.();
      },
    }
  );

  return (
    <Formik
      initialValues={
        props.values
          ? props.values
          : {
              event_date: DateTime.now().toFormat("yyyy-LL-dd"),
              book: "",
              description: "",
              wager_cents: "",
              free_bet_cents: "",
              potential_payout_cents: "",
            }
      }
      validationSchema={newBetSchema}
      onSubmit={(values, { resetForm }) => {
        console.log(values);
        // same shape as initial values
        const transformed = newBetSchema.cast(values);
        console.log(transformed);
        newBetMutation.mutate(
          {
            id: props.values?.id,
            user_id: props.userId,
            event_date: transformed.event_date,
            book: transformed.book || "",
            description: transformed.description || "",
            wager_cents: transformed.wager_cents || 0,
            free_bet_cents: transformed.free_bet_cents || 0,
            potential_payout_cents: transformed.potential_payout_cents || 0,
          },
          {
            onSuccess: () => {
              resetForm();
            },
          }
        );
      }}
    >
      {({
        errors,
        handleSubmit,
        setFieldValue,
        values,
        handleChange,
        isValid,
      }) => (
        <Form>
          <Form.Control
            type="date"
            name="event_date"
            onChange={(e) => {
              console.log(e.target.value);
              setFieldValue("event_date", e.target.value);
            }}
            value={values.event_date}
          />
          <InputGroup>
            <Form.Control
              type="text"
              name="book"
              onChange={handleChange}
              isInvalid={!!errors.book}
              placeholder="Book"
              value={values.book}
            />
            <Button
              variant="outline-secondary"
              onClick={() => setFieldValue("book", "FD")}
            >
              FD
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setFieldValue("book", "DK")}
            >
              DK
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setFieldValue("book", "CZR")}
            >
              CZR
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setFieldValue("book", "MGM")}
            >
              MGM
            </Button>
            <Form.Control.Feedback type="invalid">
              {errors.book}
            </Form.Control.Feedback>
          </InputGroup>

          <Form.Control
            type="text"
            name="description"
            onChange={handleChange}
            isInvalid={!!errors.description}
            placeholder="Description"
            value={values.description}
            spellCheck={false}
            autoComplete="off"
            autoCorrect="off"
          />
          <Form.Control.Feedback type="invalid">
            {errors.description}
          </Form.Control.Feedback>
          <InputGroup>
            <Form.Control
              type="text"
              name="wager_cents"
              onChange={handleChange}
              isInvalid={!!errors.wager_cents}
              placeholder="Wager"
              value={values.wager_cents}
            />
            <Button
              variant="outline-secondary"
              onClick={() => setFieldValue("wager_cents", 10)}
            >
              $10
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setFieldValue("wager_cents", 20)}
            >
              $20
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setFieldValue("wager_cents", 50)}
            >
              $50
            </Button>
            <Form.Control.Feedback type="invalid">
              {errors.wager_cents}
            </Form.Control.Feedback>
          </InputGroup>
          <Form.Control
            type="text"
            name="free_bet_cents"
            onChange={handleChange}
            isInvalid={!!errors.free_bet_cents}
            placeholder="Free bet amount"
            value={values.free_bet_cents}
          />
          <Form.Control.Feedback type="invalid">
            {errors.free_bet_cents}
          </Form.Control.Feedback>
          <Form.Control
            type="text"
            name="potential_payout_cents"
            onChange={handleChange}
            isInvalid={!!errors.potential_payout_cents}
            placeholder="Potential payout"
            value={values.potential_payout_cents}
          />
          <Form.Control.Feedback type="invalid">
            {errors.potential_payout_cents}
          </Form.Control.Feedback>
          <Button onClick={() => handleSubmit()} disabled={!isValid}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default NewBetForm;
