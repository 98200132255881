import { DateTime } from "luxon";
import { object, string, number, InferType } from "yup";

export const transformToCents = (
  val: any,
  originalVal: string = ""
): number => {
  if (isNaN(val)) {
    if (originalVal.indexOf(",") > -1) {
      return transformToCents(
        number().cast(originalVal.replaceAll(",", "")),
        originalVal
      );
    }
    return 0;
  }
  const strVal = "" + val;
  const dotIndex = strVal.indexOf(".");
  if (dotIndex === -1) {
    return parseInt(strVal) * 100;
  }
  const beforeDot = strVal.substring(0, dotIndex);
  const afterDot = strVal.substring(dotIndex + 1).substring(0, 2);
  const multiplier = afterDot.length === 2 ? 1 : 10;
  return parseInt((beforeDot + afterDot).replace(".", "")) * multiplier;
};

const dollarsToCents = number().transform(transformToCents).min(0);

export const newBetSchema = object({
  event_date: string().default(() => DateTime.now().toFormat("yyyy-LL-dd")),
  book: string().required(),
  description: string().required(),
  wager_cents: dollarsToCents.required(),
  free_bet_cents: dollarsToCents.default(0),
  potential_payout_cents: dollarsToCents.required(),
});

export type NewBet = InferType<typeof newBetSchema>;
