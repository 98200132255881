import React from "react";
import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import Auth from "./Auth";
import Main from "./Main";
import { Session } from "@supabase/supabase-js";
import Container from "react-bootstrap/Container";

export default function App() {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <Container>
      {!session ? <Auth /> : <Main key={session.user.id} session={session} />}
    </Container>
  );
}
