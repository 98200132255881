import { DateTime } from "luxon";
import React from "react";
import { BetsResponseSuccess, ChangesByDateSuccess } from "../queries";
import ListGroup from "react-bootstrap/ListGroup";
import Currency from "./Currency";

const Summary = (props: {
  changesByDate: ChangesByDateSuccess;
  bets: BetsResponseSuccess;
}) => {
  const todayDt = DateTime.now().startOf("day");
  const netToday = props.changesByDate?.filter(
    (row) => row.event_date && DateTime.fromISO(row.event_date).equals(todayDt)
  )[0]?.change_cents;

  const totalNet = props.changesByDate?.reduce(
    (acc, row) => acc + (row.change_cents || 0),
    0
  );

  const betsToday = (props.bets || []).filter((bet) =>
    DateTime.fromISO(bet.event_date).equals(todayDt)
  );
  const atStakeToday = betsToday.reduce(
    (acc, row) => acc + (row.wager_cents || 0),
    0
  );
  const atStake = props.bets?.reduce(
    (acc, row) => acc + (row.wager_cents || 0),
    0
  );
  const potentialWinToday = betsToday.reduce(
    (acc, row) => acc + (row.potential_payout_cents || 0),
    0
  );

  const minNetToday = (netToday || 0) - atStakeToday;
  const maxNetToday = (netToday || 0) + potentialWinToday - atStakeToday;

  return (
    <ListGroup>
      <ListGroup.Item>
        Total net:{" "}
        <strong>
          <Currency cents={totalNet} change />
        </strong>
      </ListGroup.Item>
      <ListGroup.Item>
        Net today:{" "}
        <strong>
          <Currency cents={netToday} change /> (
          <Currency cents={minNetToday} change />
          /
          <Currency cents={maxNetToday} change />)
        </strong>
      </ListGroup.Item>
      <ListGroup.Item>
        At stake:{" "}
        <strong>
          <Currency cents={atStake} />
        </strong>
      </ListGroup.Item>
      <ListGroup.Item>
        At stake today:{" "}
        <strong>
          <Currency cents={atStakeToday} />
        </strong>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default Summary;
