import React, { useState } from "react";
import { Button, ButtonGroup, Form, Table } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import {
  BetRow,
  BetsResponseSuccess,
  updateLose,
  updatePush,
  updateWin,
} from "../queries";
import Currency from "./Currency";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { DateTime } from "luxon";

const BetsTable = (props: {
  bets: BetsResponseSuccess;
  onOpenModal: (bet: BetRow) => void;
}) => {
  const queryClient = useQueryClient();
  const gradeWinMutation = useMutation<unknown, unknown, BetRow>(
    async (bet) => await updateWin(bet),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bets");
        queryClient.invalidateQueries("changeByDate");
      },
    }
  );

  const gradeLoseMutation = useMutation<unknown, unknown, BetRow>(
    async (bet) => await updateLose(bet),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bets");
        queryClient.invalidateQueries("changeByDate");
      },
    }
  );

  const gradePushMutation = useMutation<unknown, unknown, BetRow>(
    async (bet) => await updatePush(bet),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bets");
        queryClient.invalidateQueries("changeByDate");
      },
    }
  );
  const [settle, setSettle] = useState(false);
  return (
    <>
      <Form.Check
        type="switch"
        id="settle-switch"
        label="Settle mode"
        checked={settle}
        onChange={() => setSettle(!settle)}
      />
      <Table hover bordered striped size="sm">
        <thead>
          <tr>
            <th>Date</th>
            <th>Book</th>
            <th>Description</th>
            <th>$</th>
            {settle && <th></th>}
          </tr>
        </thead>
        <tbody>
          {props.bets &&
            props.bets.map((bet) => (
              <tr key={bet.id}>
                <td>{DateTime.fromISO(bet.event_date).toFormat("L/d")}</td>
                <td>{bet.book}</td>
                <td>{bet.description}</td>
                <td>
                  <Container>
                    <Row>
                      <Currency cents={bet.wager_cents} />
                    </Row>
                    <Row>
                      <Currency cents={bet.potential_payout_cents} />
                    </Row>
                  </Container>
                </td>
                {settle && (
                  <td>
                    <ButtonGroup vertical>
                      <Button
                        variant="success"
                        onClick={() => gradeWinMutation.mutate(bet)}
                      >
                        Win
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => gradeLoseMutation.mutate(bet)}
                      >
                        Lose
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => gradePushMutation.mutate(bet)}
                      >
                        Push
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => props.onOpenModal(bet)}
                      >
                        Edit
                      </Button>
                    </ButtonGroup>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default BetsTable;
