import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ChangesByDateSuccess } from "../queries";
import { DateTime } from "luxon";
import { formatCentsToDollars } from "../helpers";

const Chart = (props: { changesByDate: ChangesByDateSuccess }) => {
  let cumulative = 0;
  const chartTs = props.changesByDate?.map((row, i) => {
    cumulative += row.change_cents || 0;
    return [
      DateTime.fromISO(row.event_date || "").toMillis(),
      formatCentsToDollars(cumulative),
    ];
  });
  const options = {
    chart: {
      zoomType: "x",
    },
    title: {
      text: "Cumulative balance over time",
    },
    subtitle: {
      text:
        document.ontouchstart === undefined
          ? "Click and drag in the plot area to zoom in"
          : "Pinch the chart to zoom in",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        text: "Balance",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "#2f7ed8"],
            [1, Highcharts.color("#2f7ed8").setOpacity(0).get("rgba")],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },

    series: [
      {
        type: "area",
        name: "Balance",
        data: chartTs,
      },
    ],
  };
  return (
    <>
      {chartTs && <HighchartsReact highcharts={Highcharts} options={options} />}
    </>
  );
};

export default Chart;
