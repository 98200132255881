import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import { Session } from "@supabase/supabase-js";
import { useQuery } from "react-query";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { BetRow, queryBets, queryChangeByDate } from "../queries";
import Chart from "./Chart";
import Summary from "./Summary";
import BetsTable from "./BetsTable";
import NewBetForm from "./NewBetForm";
import ChangeCalendar from "./ChangeCalendar";
import Header from "./Header";

const betRowToFormValues = (betRow: BetRow) => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
  };
  const formatter = new Intl.NumberFormat("en-US", options);
  return {
    ...betRow,
    description: betRow.description || "",
    wager_cents: formatter.format(betRow.wager_cents / 100),
    potential_payout_cents: formatter.format(
      betRow.potential_payout_cents / 100
    ),
    free_bet_cents: formatter.format(betRow.free_bet_cents / 100),
  };
};

const Main = (props: { session: Session }) => {
  const { isLoading: betsLoading, data: bets } = useQuery("bets", () =>
    queryBets().then((res) => res.data)
  );

  const { data: changesByDate } = useQuery("changeByDate", () =>
    queryChangeByDate().then((res) => res.data)
  );

  const [showUpdateModal, setShowUpdateModal] = useState<BetRow | null>(null);
  const handleClose = () => setShowUpdateModal(null);

  return (
    <Container>
      <Row>
        <Col>
          <Header />
        </Col>
        <Col>
          <Button
            variant="outline-dark"
            onClick={() => supabase.auth.signOut()}
            className="float-end"
          >
            Sign Out
          </Button>
        </Col>
      </Row>
      {betsLoading ? (
        "Loading..."
      ) : (
        <>
          <Row>
            <Col>
              {changesByDate && bets && (
                <Summary changesByDate={changesByDate} bets={bets} />
              )}
            </Col>
            <Col>
              {changesByDate && (
                <ChangeCalendar changesByDate={changesByDate} />
              )}
            </Col>
            <Col>
              {changesByDate && <Chart changesByDate={changesByDate} />}
            </Col>
          </Row>
          <Row>
            {bets && (
              <BetsTable
                bets={bets}
                onOpenModal={(bet) => setShowUpdateModal(bet)}
              />
            )}
          </Row>
          <Row>
            <Modal show={!!showUpdateModal} onHide={handleClose} size="sm">
              <Modal.Header closeButton>
                <Modal.Title>Edit bet</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <NewBetForm
                  userId={props.session.user.id}
                  values={
                    showUpdateModal
                      ? betRowToFormValues(showUpdateModal)
                      : undefined
                  }
                  onSuccess={handleClose}
                />
              </Modal.Body>
            </Modal>
            <NewBetForm userId={props.session.user.id} />
          </Row>
        </>
      )}
    </Container>
  );
};

export default Main;
